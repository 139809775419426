<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Playbooks <span>User Actions</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" ref="playbook-button-form" class="playbook-button-form">
                <div class="modal_body">
                    <ul class="buttonList">
                        <template v-for="(button, b) of selectedPlaybook.button_ordering" :key="b">
                            <!-- <li :class="{ active : activeTab == 'ask' }" v-if="button == 'ask'"> -->
                                <!-- <h3 @click="toggleDetails($event)">Member Asks A Question <i class="fas fa-angle-up"></i></h3> -->
                                <div class="setting_wpr" v-if="button == 'ask' && activeTab == 'ask'">
                                    <div class="capsule_elm mb-5">
                                        <h5>Display Button</h5>
                                        <label :for="`show-${button}-button`" class="switch_option capsule_btn p-0">
                                            <input type="checkbox" :id="`show-${button}-button`" :true-value="1" :false-value="0" v-model="form.recipient_ask.display" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div v-if="form.recipient_ask.display" class="mt-2">
                                        <ul class="tab_sec">
                                            <li :class="tab1 == 'general' ? 'active' : ''" @click="tab1 = 'general'">Settings</li>
                                            <li :class="tab1 == 'notific' ? 'active' : ''" @click="tab1 = 'notific'">Notifications</li>
                                        </ul>
                                        <div v-if="tab1 == 'general'" class="mt-2">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Button Text</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.recipient_ask_button_text }">
                                                        <Field autocomplete="off" name="recipient_ask_button_text" type="text" placeholder="ex: Button Content" v-model="form.recipient_ask.button_text" />
                                                    </div>
                                                    <ErrorMessage name="recipient_ask_button_text" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Select link</label>
                                                    <div class="field_wpr">
                                                        <multiselect
                                                            v-model="link"
                                                            :groups="true"
                                                            :searchable="true"
                                                            :options="links"
                                                            valueProp="id"
                                                            label="title"
                                                            placeholder="Select link"
                                                            :groupSelect="false"
                                                            @select="handleLinkSelect"
                                                            :groupHideEmpty="true"
                                                        >
                                                            <template v-slot:option="{ option }">
                                                                <span v-if="option.$isLabel">
                                                                    {{ option.$groupLabel }}
                                                                </span>
                                                                <span v-else>{{ option.title }}</span>
                                                        </template>
                                                        </multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Button Link</label>
                                                    <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.recipient_ask_link }">
                                                        <Field autocomplete="off" name="recipient_ask_link" type="text" placeholder="https://superfitcoaching.com" v-model="form.recipient_ask.link" rules="url" label="button link" />
                                                        <span class="prefix">URL</span>
                                                    </div>
                                                    <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                    <ErrorMessage name="recipient_ask_link" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Button Color</label>
                                                    <Field autocomplete="off" name="recipient_ask_button_color" v-model="form.recipient_ask.button_color" type="text" label="element color">
                                                        <color-picker v-model="form.recipient_ask.button_color" :classes="{ 'has-error': errors.recipient_ask_button_color }" />
                                                    </Field>
                                                    <ErrorMessage name="recipient_ask_button_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Button Text Color</label>
                                                    <Field autocomplete="off" name="recipient_ask_button_text_color" v-model="form.recipient_ask.button_txt_color" type="text" label="element color">
                                                        <color-picker v-model="form.recipient_ask.button_txt_color" :classes="{ 'has-error': errors.recipient_ask_button_text_color }" />
                                                    </Field>
                                                    <ErrorMessage name="recipient_ask_button_text_color" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="tab1 == 'notific'" class="mt-2 mb-5">
                                            <div class="capsule_elm">
                                                <h5>{{form.recipient_ask.has_notification ? 'Sending Method' : 'Notifications?'}}</h5>
                                                <label for="action_notify" class="switch_option capsule_btn border-0 mb-2">
                                                    <input type="checkbox" id="action_notify" :true-value="1" :false-value="0" v-model="form.recipient_ask.has_notification" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div v-show="form.recipient_ask.notification">
                                                <div class="section_wpr mb-3">
                                                    <div class="edit_section">
                                                        <sending-method v-model="form.recipient_ask.notification" />
                                                    </div>
                                                </div>
                                                <div v-if="loadedPanel && (form.recipient_ask.notification == 1 || form.recipient_ask.notification == 3)">
                                                    <email-component v-model="askNotificationEmail" :errors="errors" :handle-default-email="handleAskDefaultEmail" preview-module="playbook" :has-recipient="true" ref="ask-email-component" />
                                                </div>
                                                <div v-if="loadedPanel && (form.recipient_ask.notification == 2 || form.recipient_ask.notification == 3)">
                                                    <sms-component v-model="form.recipient_ask.sms_message" :number="form.recipient_ask.sms_recipient" :media="form.recipient_ask.media" :handle-update="handleAskSMS" preview-module="playbook" media-field-name="ask_media" :has-number-field="true" :handle-default-sms="handleAskDefaultSms" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <!-- </li> -->
                            <!-- <li :class="{ active : activeTab == 'complete' }" v-if="button == 'complete'"> -->
                                <!-- <h3 @click="toggleDetails($event)">Member Completes Procedure <i class="fas fa-angle-up"></i></h3> -->
                                <div class="setting_wpr" v-if="button == 'complete' && activeTab == 'complete'">
                                    <div class="capsule_elm mb-5">
                                        <h5>Display Button?</h5>
                                        <label :for="`show-${button}-button`" class="switch_option capsule_btn p-0">
                                            <input type="checkbox" :id="`show-${button}-button`" :true-value="1" :false-value="0" v-model="form.recipient_complete.display" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div v-if="form.recipient_complete.display" class="mt-2">
                                        <ul class="tab_sec">
                                            <li :class="tab2 == 'general' ? 'active' : ''" @click="tab2 = 'general'">Settings</li>
                                            <li :class="tab2 == 'notific' ? 'active' : ''" @click="tab2 = 'notific'">Notifications</li>
                                        </ul>
                                        <div v-if="tab2 == 'general'" class="mt-2">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Button Text</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.recipient_complete_button_text }">
                                                        <Field autocomplete="off" name="recipient_complete_button_text" type="text" placeholder="ex: Button Content" v-model="form.recipient_complete.button_text" />
                                                    </div>
                                                    <ErrorMessage name="recipient_complete_button_text" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Select link</label>
                                                    <div class="field_wpr">
                                                        <multiselect
                                                            v-model="link"
                                                            :groups="true"
                                                            :searchable="true"
                                                            :options="links"
                                                            valueProp="id"
                                                            label="title"
                                                            placeholder="Select link"
                                                            :groupSelect="false"
                                                            @select="handleLinkSelect"
                                                            :groupHideEmpty="true"
                                                        >
                                                            <template v-slot:option="{ option }">
                                                                <span v-if="option.$isLabel">
                                                                    {{ option.$groupLabel }}
                                                                </span>
                                                                <span v-else>{{ option.title }}</span>
                                                        </template>
                                                        </multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Button Link</label>
                                                    <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.recipient_complete_link }">
                                                        <Field autocomplete="off" name="recipient_complete_link" type="text" placeholder="https://superfitcoaching.com" v-model="form.recipient_complete.link" rules="url" label="button link" />
                                                        <span class="prefix">URL</span>
                                                    </div>
                                                    <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                    <ErrorMessage name="recipient_complete_link" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Button Color</label>
                                                    <Field autocomplete="off" name="recipient_complete_button_color" v-model="form.recipient_complete.button_color" type="text" label="element color">
                                                        <color-picker v-model="form.recipient_complete.button_color" :classes="{ 'has-error': errors.recipient_complete_button_color }" />
                                                    </Field>
                                                    <ErrorMessage name="recipient_complete_button_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Button Text Color</label>
                                                    <Field autocomplete="off" name="recipient_complete_button_text_color" v-model="form.recipient_complete.button_txt_color" type="text" label="element color">
                                                        <color-picker v-model="form.recipient_complete.button_txt_color" :classes="{ 'has-error': errors.recipient_complete_button_text_color }" />
                                                    </Field>
                                                    <ErrorMessage name="recipient_complete_button_text_color" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="tab2 == 'notific'" class="mt-2 mb-5">
                                            <div class="capsule_elm">
                                                <h5>{{form.recipient_complete.has_notification ? 'Sending Method' : 'Notifications?'}}</h5>
                                                <label for="action_complete_notify" class="switch_option capsule_btn border-0">
                                                    <input type="checkbox" id="action_complete_notify" :true-value="1" :false-value="0" v-model="form.recipient_complete.has_notification" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div v-show="form.recipient_complete.notification">
                                                <div class="section_wpr mb-3">
                                                    <div class="edit_section">
                                                        <sending-method v-model="form.recipient_complete.notification" />
                                                    </div>
                                                </div>
                                                <div v-if="loadedPanel && (form.recipient_complete.notification == 1 || form.recipient_complete.notification == 3)">
                                                    <email-component v-model="completeNotificationEmail" :errors="errors" :handle-default-email="handleCompleteDefaultEmail" :has-recipient="true" preview-module="playbook" ref="complete-email-component" />
                                                </div>
                                                <div v-if="loadedPanel &&(form.recipient_complete.notification == 2 || form.recipient_complete.notification == 3)">
                                                    <sms-component v-model="form.recipient_complete.sms_message" :number="form.recipient_complete.sms_recipient" :media="form.recipient_complete.media" :handle-update="handleCompleteSMS" preview-module="playbook" media-field-name="complete_media" :has-number-field="true" :handle-default-sms="handleCompleteDefaultSms" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <!-- </li> -->
                            <!-- <li :class="{ active : activeTab == 'understand' }" v-if="button == 'understand'"> -->
                                <!-- <h3 @click="toggleDetails($event)">Member Understood Procedure <i class="fas fa-angle-up"></i></h3> -->
                                <div class="setting_wpr" v-if="button == 'understand' && activeTab == 'understand'">
                                    <div class="capsule_elm mb-5">
                                        <h5>Display Button?</h5>
                                        <label :for="`show-${button}-button`" class="switch_option capsule_btn p-0">
                                            <input type="checkbox" :id="`show-${button}-button`" :true-value="1" :false-value="0" v-model="form.recipient_understand.display" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div v-if="form.recipient_understand.display" class="mt-2">
                                        <ul class="tab_sec">
                                            <li :class="tab3 == 'general' ? 'active' : ''" @click="tab3 = 'general'">Settings</li>
                                            <li :class="tab3 == 'notific' ? 'active' : ''" @click="tab3 = 'notific'">Notifications</li>
                                        </ul>
                                        <div v-if="tab3 == 'general'" class="mt-2">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Button Text</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.recipient_understand_button_text }">
                                                        <Field autocomplete="off" name="recipient_understand_button_text" type="text" placeholder="ex: Button Content" v-model="form.recipient_understand.button_text" />
                                                    </div>
                                                    <ErrorMessage name="recipient_understand_button_text" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Select link</label>
                                                    <div class="field_wpr">
                                                        <multiselect
                                                            v-model="link"
                                                            :groups="true"
                                                            :searchable="true"
                                                            :options="links"
                                                            valueProp="id"
                                                            label="title"
                                                            placeholder="Select link"
                                                            :groupSelect="false"
                                                            @select="handleLinkSelect"
                                                            :groupHideEmpty="true"
                                                        >
                                                            <template v-slot:option="{ option }">
                                                                <span v-if="option.$isLabel">
                                                                    {{ option.$groupLabel }}
                                                                </span>
                                                                <span v-else>{{ option.title }}</span>
                                                        </template>
                                                        </multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Button Link</label>
                                                    <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.recipient_understand_link }">
                                                        <Field autocomplete="off" name="recipient_understand_link" type="text" placeholder="https://superfitcoaching.com" v-model="form.recipient_understand.link" rules="url" label="button link" />
                                                        <span class="prefix">URL</span>
                                                    </div>
                                                    <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                    <ErrorMessage name="recipient_understand_link" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Button Color</label>
                                                    <Field autocomplete="off" name="recipient_understand_button_color" v-model="form.recipient_understand.button_color" type="text" label="element color">
                                                        <color-picker v-model="form.recipient_understand.button_color" :classes="{ 'has-error': errors.recipient_understand_button_color }" />
                                                    </Field>
                                                    <ErrorMessage name="recipient_understand_button_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Button Text Color</label>
                                                    <Field autocomplete="off" name="recipient_understand_button_text_color" v-model="form.recipient_understand.button_txt_color" type="text" label="element color">
                                                        <color-picker v-model="form.recipient_understand.button_txt_color" :classes="{ 'has-error': errors.recipient_understand_button_text_color }" />
                                                    </Field>
                                                    <ErrorMessage name="recipient_understand_button_text_color" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="tab3 == 'notific'" class="mt-2 mb-5">
                                            <div class="capsule_elm">
                                                <h5>{{form.recipient_understand.has_notification ? 'Sending Method' : 'Notifications?'}}</h5>
                                                <label for="action_understand_notify" class="switch_option capsule_btn border-0 mb-2">
                                                    <input type="checkbox" id="action_understand_notify" :true-value="1" :false-value="0" v-model="form.recipient_understand.has_notification" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div v-show="form.recipient_understand.notification">
                                                <div class="section_wpr mb-3">
                                                    <div class="edit_section">
                                                        <sending-method v-model="form.recipient_understand.notification" />
                                                    </div>
                                                </div>
                                                <div v-if="loadedPanel && (form.recipient_understand.notification == 1 || form.recipient_understand.notification == 3)">
                                                    <email-component v-model="understandNotificationEmail" :errors="errors" :handle-default-email="handleUnderstandDefaultEmail" :has-recipient="true" preview-module="playbook" ref="understand-email-component" />
                                                </div>
                                                <div v-if="loadedPanel && (form.recipient_understand.notification == 2 || form.recipient_understand.notification == 3)">
                                                    <sms-component v-model="form.recipient_understand.sms_message" :number="form.recipient_understand.sms_recipient" :media="form.recipient_understand.media" preview-module="playbook" :handle-update="handleUnderstandSMS" media-field-name="understand_media" :has-number-field="true" :handle-default-sms="handleUnderstandDefaultSms" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <!-- </li> -->
                        </template>
                    </ul>
                    <div class="setting_wpr mt-2">
                        <div class="capsule_elm">
                            <h5>Apply Tag</h5>
                            <label for="apply_tag" class="switch_option capsule_btn border-0 m-0">
                                <input type="checkbox" id="apply_tag" :true-value="form.tag_option" :false-value="0" v-model="form.apply_tag" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                        <div v-if="form.apply_tag">
                            <h3 class="sub_header2 mt-3">Tag Option</h3>
                            <div class="edit_section">
                                <div class="capsule_elm border-bottom">
                                    <h5>A Question is Asked</h5>
                                    <label for="question" class="switch_option capsule_btn m-0">
                                        <input type="radio" id="question" :value="1" v-model="form.tag_option" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="capsule_elm border-bottom">
                                    <h5>Understand Procedure</h5>
                                    <label for="understand" class="switch_option capsule_btn m-0">
                                        <input type="radio" id="understand" :value="2" v-model="form.tag_option" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="capsule_elm">
                                    <h5>Completes Procedure</h5>
                                    <label for="completes" class="switch_option capsule_btn m-0">
                                        <input type="radio" id="completes" :value="3" v-model="form.tag_option" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                            </div>
                            <div class="form_grp mt-2">
                                <div class="group_item">
                                    <label class="input_label">
                                        Add Tags
                                        <a class="option" @click="createTags = !createTags">Create a Tag</a>
                                    </label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.selected_tags }">
                                        <Field autocomplete="off" name="selected_tags" v-model="form.selected_tags">
                                            <multiselect
                                                v-model="form.selected_tags"
                                                mode="tags"
                                                label="name"
                                                value-prop="id"
                                                :searchable="true"
                                                :group-select="true"
                                                :options="tagsGroup"
                                                :groups="true"
                                                group-label="type"
                                                group-options="tags"
                                                :group-hide-empty="true"
                                                placeholder="Select Tags"
                                            >
                                                <template v-slot:grouplabel="{ group }">
                                                    <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                                </template>
                                                <template v-slot:option="{ option }">
                                                    <span>{{ option.name }}</span>
                                                </template>
                                                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                    <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                      {{ option.name }}
                                                      <span
                                                        v-if="!disabled"
                                                        class="multiselect-tag-remove"
                                                        @mousedown.prevent="handleTagRemove(option, $event)"
                                                      >
                                                          <span class="multiselect-tag-remove-icon"></span>
                                                      </span>
                                                    </div>
                                                </template>
                                            </multiselect>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="selected_tags" class="text-danger" />
                                </div>
                            </div>
                            <div v-if="createTags" class="form_grp">
                                <Form v-slot="{ errors }" ref="tag-form">
                                    <div class="group_item">
                                        <label class="input_label">Create a New Tag</label>
                                        <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                                            <Field autocomplete="off" type="text" v-model="tagForm.name" name="name" placeholder="Enter Tag Name.." rules="required" />
                                            <span class="suffix pointer" @click="handleTagCreate"><i class="fa fa-spinner fa-spin" v-if="tagLoader"></i> {{ tagLoader ? 'Creating' : 'Create' }}</span>
                                        </div>
                                        <ErrorMessage name="name" class="text-danger" />
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="playbookUpdateLoader" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button :disabled="playbookUpdateLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="playbookUpdateLoader"></i> {{ playbookUpdateLoader ? 'Updating' : 'Update' }}</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const SendingMethod = defineAsyncComponent(() => import('@/components/SendingMethod'))
    const EmailComponent = defineAsyncComponent(() => import('@/components/EmailComponent'))
    const SmsComponent = defineAsyncComponent(() => import('@/components/SmsComponent'))
    const ColorPicker = defineAsyncComponent(() => import('@/components/ColorPicker'))

    import Multiselect from '@vueform/multiselect'

    export default {
        name: 'Playbook Offer Headline',

        data () {
            return {
                createTags: false,
                loadedPanel: false,
                tab1: 'general',
                tab2: 'general',
                tab3: 'general',
                form: {
                    recipient_ask: {},
                    recipient_complete: {},
                    recipient_understand: {},
                    selected_tags: [],
                    tag_option: {},
                    apply_tag: 0,
                } ,
                tagForm: {
                    name: '',
                    type: 'leads',
                    uniqueId: 'bulk-action',
                },
                askNotificationEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                completeNotificationEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                understandNotificationEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                ask_media: [],
                complete_media: [],
                understand_media: [],
                link: '',
            };
        },

        props: {
            modelValue: Boolean,
            activeTab: String,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            SendingMethod,
            EmailComponent,
            SmsComponent,
            ColorPicker,
            Multiselect,
        },

        watch: {
            selectedPlaybook () {
                const vm = this;

                vm.resetForm();
            },

            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.resetForm();

                    setTimeout(function () {
                        vm.loadedPanel = true;
                    }, 1000);
                } else{
                    this.link = '';
                }
            },
        },

        computed: mapState({
            selectedPlaybook: state => state.playbookModule.selectedPlaybook,
            playbookUpdateLoader: state => state.playbookModule.playbookUpdateLoader,
            tagsGroup: state => state.tagModule.tagsGroup,
            tagLoader: state => state.tagModule.tagLoader,
            user: state => state.authModule.user,
            links: state => state.commonModule.definedLinks,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                updatePlaybookSetting: 'playbookModule/updatePlaybookSetting',
                getTags: 'tagModule/getTags',
                createTag: 'tagModule/createTag',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
                vm.loadedPanel = false;
            },

            resetForm () {
                const vm = this;
                const playbook = JSON.parse(JSON.stringify(vm.selectedPlaybook));

                vm.form = {
                    playbook_id: playbook.id,
                    recipient_ask: playbook.recipient_ask ? playbook.recipient_ask : {},
                    recipient_complete: playbook.recipient_complete ? playbook.recipient_complete : {},
                    recipient_understand: playbook.recipient_understand ? playbook.recipient_understand : {},
                    selected_tags: playbook.selected_tags ? playbook.selected_tags : [],
                    tag_option: playbook.tag_option ? parseInt(playbook.tag_option) : 1,
                    apply_tag: playbook.tag_option ? parseInt(playbook.tag_option) : 0,
                };

                vm.form.recipient_ask.display             = vm.form.recipient_ask.display ? parseInt(vm.form.recipient_ask.display): 0;
                vm.form.recipient_complete.display        = vm.form.recipient_complete.display ? parseInt(vm.form.recipient_complete.display): 0;
                vm.form.recipient_understand.display      = vm.form.recipient_understand.display ? parseInt(vm.form.recipient_understand.display): 0;
                vm.form.recipient_ask.notification        = vm.form.recipient_ask.notification ? parseInt(vm.form.recipient_ask.notification): 0;
                vm.form.recipient_complete.notification   = vm.form.recipient_complete.notification ? parseInt(vm.form.recipient_complete.notification): 0;
                vm.form.recipient_understand.notification = vm.form.recipient_understand.notification ? parseInt(vm.form.recipient_understand.notification): 0;
                vm.ask_media                              = playbook.recipient_ask ? playbook.recipient_ask.media : [];
                vm.complete_media                         = playbook.recipient_complete ? playbook.recipient_complete.media : [];
                vm.understand_media                       = playbook.recipient_understand ? playbook.recipient_understand.media : [];

                if (vm.form.recipient_ask.has_notification == undefined) {
                    vm.form.recipient_ask.has_notification = vm.form.recipient_ask.notification ? parseInt(vm.form.recipient_ask.notification): 0;
                    vm.form.recipient_ask.has_notification = vm.form.recipient_ask.has_notification > 1 ? 1 : vm.form.recipient_ask.has_notification;
                }

                if (vm.form.recipient_complete.has_notification == undefined) {
                    vm.form.recipient_complete.has_notification = vm.form.recipient_complete.notification ? parseInt(vm.form.recipient_complete.notification): 0;
                    vm.form.recipient_complete.has_notification = vm.form.recipient_complete.has_notification > 1 ? 1 : vm.form.recipient_complete.has_notification;
                }

                if (vm.form.recipient_understand.has_notification == undefined) {
                    vm.form.recipient_understand.has_notification = vm.form.recipient_understand.notification ? parseInt(vm.form.recipient_understand.notification): 0;
                    vm.form.recipient_understand.has_notification = vm.form.recipient_understand.has_notification > 1 ? 1 : vm.form.recipient_understand.has_notification;
                }

                vm.tagForm = {
                    name: '',
                    type: 'leads',
                    uniqueId: 'playbook-button',
                };

                vm.askNotificationEmail = {
                    defaultHandler: true,
                    recipient: playbook.recipient_ask ? playbook.recipient_ask.email_recipient : (vm.user.reply_to_email ? vm.user.reply_to_email : ''),
                    subject: playbook.recipient_ask ? playbook.recipient_ask.email_subject : '',
                    email: playbook.recipient_ask ? playbook.recipient_ask.email_message : '',
                    is_email_logo: playbook.ask_show_email_logo,
                    is_signature: playbook.recipient_ask && playbook.recipient_ask.is_signature ? playbook.recipient_ask.is_signature : 0,
                    signature_id: playbook.recipient_ask && playbook.recipient_ask.signature_id ? playbook.recipient_ask.signature_id : 0,
                    show_email_logo: playbook.ask_show_email_logo,
                    email_logo: playbook.ask_email_logo,
                    module_id: playbook.id,
                };

                vm.completeNotificationEmail = {
                    defaultHandler: true,
                    recipient: playbook.recipient_complete ? playbook.recipient_complete.email_recipient : (vm.user.reply_to_email ? vm.user.reply_to_email : ''),
                    subject: playbook.recipient_complete ? playbook.recipient_complete.email_subject : '',
                    email: playbook.recipient_complete ? playbook.recipient_complete.email_message : '',
                    is_email_logo: playbook.complete_show_email_logo,
                    is_signature: playbook.recipient_complete && playbook.recipient_complete.is_signature ? playbook.recipient_complete.is_signature : 0,
                    signature_id: playbook.recipient_complete && playbook.recipient_complete.signature_id ? playbook.recipient_complete.signature_id : 0,
                    show_email_logo: playbook.complete_show_email_logo,
                    email_logo: playbook.complete_email_logo,
                    module_id: playbook.id,
                };

                vm.understandNotificationEmail = {
                    defaultHandler: true,
                    recipient: playbook.recipient_understand ? playbook.recipient_understand.email_recipient : (vm.user.reply_to_email ? vm.user.reply_to_email : ''),
                    subject: playbook.recipient_understand ? playbook.recipient_understand.email_subject : '',
                    email: playbook.recipient_understand ? playbook.recipient_understand.email_message : '',
                    is_email_logo: playbook.understand_show_email_logo,
                    is_signature: playbook.recipient_understand && playbook.recipient_understand.is_signature ? playbook.recipient_understand.is_signature : 0,
                    signature_id: playbook.recipient_understand && playbook.recipient_understand.signature_id ? playbook.recipient_understand.signature_id : 0,
                    show_email_logo: playbook.understand_show_email_logo,
                    email_logo: playbook.understand_email_logo,
                    module_id: playbook.id,
                };
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;
                params.setFieldError  = setFieldError;
                params.recipient_ask.email_recipient          = vm.askNotificationEmail.recipient;
                params.recipient_ask.email_subject            = vm.askNotificationEmail.subject;
                params.recipient_ask.email_message            = vm.askNotificationEmail.email;
                params.recipient_ask.is_signature             = vm.askNotificationEmail.is_signature;
                params.recipient_ask.signature_id             = vm.askNotificationEmail.signature_id;
                params.ask_show_email_logo                    = vm.askNotificationEmail.show_email_logo;
                params.ask_email_logo                         = vm.askNotificationEmail.email_logo;
                params.recipient_complete.email_recipient     = vm.completeNotificationEmail.recipient;
                params.recipient_complete.email_subject       = vm.completeNotificationEmail.subject;
                params.recipient_complete.email_message       = vm.completeNotificationEmail.email;
                params.recipient_complete.is_signature        = vm.completeNotificationEmail.is_signature;
                params.recipient_complete.signature_id        = vm.completeNotificationEmail.signature_id;
                params.complete_show_email_logo               = vm.completeNotificationEmail.show_email_logo;
                params.complete_email_logo                    = vm.completeNotificationEmail.email_logo;
                params.recipient_understand.email_recipient   = vm.understandNotificationEmail.recipient;
                params.recipient_understand.email_subject     = vm.understandNotificationEmail.subject;
                params.recipient_understand.email_message     = vm.understandNotificationEmail.email;
                params.recipient_understand.is_signature      = vm.understandNotificationEmail.is_signature;
                params.recipient_understand.signature_id      = vm.understandNotificationEmail.signature_id;
                params.understand_show_email_logo             = vm.understandNotificationEmail.show_email_logo;
                params.understand_email_logo                  = vm.understandNotificationEmail.email_logo;
                params.recipient_ask.media                    = vm.ask_media;
                params.recipient_complete.media               = vm.complete_media;
                params.recipient_understand.media             = vm.understand_media;

                vm.updatePlaybookSetting(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }
                });
            },

            toggleDetails (e) {
                let el = e.currentTarget.closest('li');
                let allEl = document.querySelectorAll('.buttonList li');

                if (el.classList.contains('active')) {
                    el.classList.remove('active');
                } else {
                    for (let i = 0; i < allEl.length; i++) {
                        allEl[i].classList.remove('active');
                    }

                    el.classList.add('active');
                }
            },

            handleTagCreate () {
                const vm = this;
                const form = vm.$refs['tag-form'];

                form.validate().then(({ valid }) => {
                    if (valid) {
                        vm.tagForm.setFieldError = form.setFieldError;

                        vm.createTag(vm.tagForm).then((result) => {
                            vm.createTags = false;
                            vm.tagForm.name = '';
                            vm.getTags();
                        });
                    }
                });
            },

            handleAskDefaultEmail () {
                const vm = this;
                let email = '<p>Hi {{company_owner}},</p>';
                    email += '<p>{{full_name}} asked this question about {{playbook_name}}:</p>';
                    email += '<p>{{question_message}}</p>';
                    email += '<p>Click {{question_link}} to answer the question.</p>';
                    email += '<p>Cheers!</p>';
                    email += '<p>Thrive Coach</p>';

                vm.askNotificationEmail.subject = 'Playbook Notification!';
                vm.askNotificationEmail.email   = email;
            },

            handleAskSMS (object) {
                const vm = this;

                vm.form.recipient_ask.sms_recipient = object.number;
            },

            handleAskDefaultSms () {
                const vm = this;

                vm.form.recipient_ask.sms_message = 'Hi {{company_owner}},\r\n{{full_name}} asked this question about {{playbook_name}}:\r\n{{question_message}}\r\nClick {{question_link_sms}} to answer the question.\r\nCheers,\r\nThrive Coach';
            },

            handleCompleteDefaultEmail () {
                const vm = this;
                let email = '<p>Hi {{company_owner}},</p>';
                    email += '<p>{{full_name}} has just completed your {{playbook_name}} playbook.</p>';
                    email += '<p>Send them a congrats text!</p>';
                    email += '<p>{{phone}}</p>';
                    email += '<p>Cheers,</p>';
                    email += '<p>Thrive Coach</p>';

                vm.completeNotificationEmail.subject = 'Playbook Notification!';
                vm.completeNotificationEmail.email   = email;
            },

            handleCompleteSMS (object) {
                const vm = this;

                vm.form.recipient_complete.sms_recipient = object.number;
            },

            handleCompleteDefaultSms () {
                const vm = this;

                vm.form.recipient_complete.sms_message = 'Hi {{company_owner}},\r\n{{full_name}} certifies they have completed your {{playbook name}} playbook. Send them a congrats and give them a final evaluation.\r\nCheers,\r\nThrive Coach';
            },

            handleUnderstandDefaultEmail () {
                const vm = this;
                let email = '<p>Hi {{company_owner}},</p>';
                    email += '<p>{{full_name}} has just informed you that they completely understand your {{playbook_name}} playbook.</p>';
                    email += '<p>You can text or call them now.</p>';
                    email += '<p>{{phone}}</p>';
                    email += '<p>Cheers,</p>';
                    email += '<p>Thrive Coach</p>';

                vm.understandNotificationEmail.subject = 'Playbook Notification!';
                vm.understandNotificationEmail.email   = email;
            },

            handleUnderstandSMS (object) {
                const vm = this;

                vm.form.recipient_understand.sms_recipient = object.number;
            },

            handleUnderstandDefaultSms () {
                const vm = this;

                vm.form.recipient_understand.sms_message = 'Hi {{company_owner}},\r\n{{full_name}} certifies they understand your {{playbook name}} playbook.\r\nCheers,\r\nThrive Coach';
            },

            handleLinkSelect (id, link) {
                const vm = this;

                vm.link  = link.id;

                if(vm.activeTab == 'ask'){
                    vm.form.recipient_ask.link = link.public_url;
                } else if(vm.activeTab == 'complete'){
                    vm.form.recipient_complete.link = link.public_url;
                } else if(vm.activeTab == 'understand'){
                    vm.form.recipient_understand.link = link.public_url;
                }
            },
        },
    }
</script>

<style scoped>
    .playbook-button-form {
        height: calc(100vh - 180px);
    }

    :deep(.color-picker .swatches li) {
        border: 0px;
    }
</style>
